// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFteBlQpxXmuPSJR8MWYCR0z6Wac_rbAo",
  authDomain: "ecommercedbclone6.firebaseapp.com",
  projectId: "ecommercedbclone6",
  storageBucket: "ecommercedbclone6.firebasestorage.app",
  messagingSenderId: "88530452547",
  appId: "1:88530452547:web:1238878edffe236d3194f6"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;